<template>
  <RevenueListComponent
    sp_id=""
    :csvFileName="`Comenow_Finance.csv`"
    :fields="[
      { key: 'status', label: 'Status' },
      { key: 'booking_id', label: 'BookingID' },
      { key: 'job_id', label: 'JobID' },
      { key: 'type', label: 'Type' },
      { key: 'created_at', label: 'Date and time' },
      { key: 'gross_revenue', label: 'Net Sales' },
      { key: 'stripe_fee', label: 'Stripe fees' },
      { key: 'payout_amount', label: 'Pay out of SP' },
      { key: 'available_payout', label: 'Pay out of CN' },
    ]"
  />
</template>

<script>
import RevenueListComponent from '@/components/RevenueListComponent';

export default {
  name: 'Revenue',
  components: {
    RevenueListComponent,
  },
};
</script>

<style></style>
