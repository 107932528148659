var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RevenueListComponent',{attrs:{"sp_id":"","csvFileName":`Comenow_Finance.csv`,"fields":[
    { key: 'status', label: 'Status' },
    { key: 'booking_id', label: 'BookingID' },
    { key: 'job_id', label: 'JobID' },
    { key: 'type', label: 'Type' },
    { key: 'created_at', label: 'Date and time' },
    { key: 'gross_revenue', label: 'Net Sales' },
    { key: 'stripe_fee', label: 'Stripe fees' },
    { key: 'payout_amount', label: 'Pay out of SP' },
    { key: 'available_payout', label: 'Pay out of CN' },
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }